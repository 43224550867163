.aboutUs {
    padding: 3rem 0rem;
    width: 100%;
}

.about-main {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.about-left {
    width: 70%;
}

.about-title h2 {
    color: #212121;
    font-size: 36px;
    line-height: 35px;
    padding-bottom: 23px;
    font-weight: bold;
}

.about-detail p {
    margin: 0 0 0px 0;
    font-weight: 400;
    font-size: 16px;
    color: #70778a;
    padding-bottom: 10px;
}

.about-right {
    width: 30%;
}

.about-right img {
    rotate: 90deg;
}

@media (min-width: 1200px) {
    .about-main {
        max-width: 1140px;
    }
}

@media only screen and (max-width: 990px) {

    .about-main {
        max-width: 720px;
    }
}
@media only screen and (max-width: 680px) {

    .about-main {
        gap: 0;
        display: block;
    }

    .about-left,
    .about-right {
        width: 100%;
    }

    .about-right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .about-main {
        max-width: 520px;
    }
}