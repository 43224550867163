.footer {
    background-color: #0075b8;
    padding: 4rem 0rem 1rem;
    width: 100%;
    color: white
}

.footer-main {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

.footer-detail img {
    margin-bottom: 25px;
    max-height: 80px;
    background-color: white;
    border-radius: 5px;
    
}

.quick-link ul li{
    padding: 5px 0px;
}

.quick-link ul li a {
    color: white;
    text-decoration: none;
}

.footer-contact ul {
    padding: 0;
    margin: 0;
}

.footer-contact ul li {
    display: flex;
    padding: 5px 0px;
    align-items: center;
    /* width: 100%; */
}
.footer-contact ul li:first-child {
    display: flex;
    padding: 5px 0px;
    align-items: baseline;
}

.footer-contact ul li a {
    text-decoration: none;
    color: white;
    width: max-content;
}

.footer-contact ul li p {
    margin: 0;
    padding: 5px 0px;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
}
.footer-contact ul li p svg {
    /* margin-right: 10px; */
    font-size: 24px;
}

.footer-copyright {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-top: 1px solid rgb(209, 209, 209);
    margin-top: 50px;
}

.footer-copyright p a {
    color: white;
    text-decoration: none;
}
.footer-copyright p{
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    color: white;
    text-decoration: none;
}
.footer-down{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.services-images {
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 10px;
}
.services-images img{
    height: 100%;
    width: 100%;
}


@media (min-width: 1200px) {
    .footer-main ,.footer-down{
        max-width: 1140px;
    }
}

@media only screen and (max-width: 990px) {
    .footer-main {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
    .footer-main ,.footer-down{
        max-width: 720px;
    }
}
@media only screen and (max-width: 680px) {
    .footer-main {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
    }
    .footer-main ,.footer-down{
        max-width: 520px;
    }

    .footer-copyright {
       display: block;
        margin-top: 20px;
        padding-top: 10px;
    }
    .footer-copyright p{
        margin: 0px;
        justify-content: center;
        padding: 0;
    }

    .footer-contact ul li:first-child {
        align-items: center;
    }

}