.FormFloat{
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background-color: rgb(0 0 0 / 36%);
}
.FormFloat-box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.FormFloat-card{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.3)
}
.FormFloat-card form{
    padding: 0px 2rem 2rem;
}
.FormFloat-card form button{
    width: 100%;
    background-color: #0075b8;
}
.FormFloat-card form button:hover{
    width: 100%;
    background-color: #003757;
}

.close-button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.close-button p{
    font-weight: 500;
    padding: 10px 15px;
    cursor: pointer;
    margin: 0;
    border-radius: 0px 5px 0px 5px;
}
.close-button p:hover{
    background-color: red;
    color: white;
}

